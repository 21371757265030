<template>
  <component
    :is="item.link.url ? 'router-link' : 'button'"
    :type="!item.link.url ? 'button' : null"
    :to="item.link.url ? localizedRoute(item.link.url) : null"
    :class="item.class"
  >
    {{ item.text }}
    <i v-if="item.iconClass" :class="item.iconClass" />
  </component>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'ButtonBlok',
  extends: Blok
}
</script>
